var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "action-collapse": "",
      "title": 'Akun - ' + _vm.akun.kategori ? _vm.akun.kategori.kategori : null
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "text-primary"
  }, [_vm._v("( " + _vm._s(_vm.akun.nomor) + " ) " + _vm._s(_vm.akun.nama))])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "text-info"
  }, [_vm._v("Saldo : Rp. " + _vm._s(_vm.akun.saldo >= 0 ? _vm.formatRupiah(_vm.akun.saldo) : "( " + _vm.formatRupiah(_vm.akun.saldo * -1) + " )"))])]), _c('b-col', {
    attrs: {
      "cols": "1"
    }
  }, [_vm.akun.jenis == 1 ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Debit")]) : _vm.akun.jenis == 2 ? _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("Kredit")]) : _c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v("-")])], 1), _c('b-col', {
    attrs: {
      "cols": "1"
    }
  }, [_vm.akun.jenis_neraca == null ? _c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v("-")]) : _vm.akun.jenis_neraca.neraca == 'Aktiva Lancar' ? _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(_vm._s(_vm.akun.jenis_neraca.neraca))]) : _vm.akun.jenis_neraca.neraca == 'Aktiva Tetap' ? _c('b-badge', {
    attrs: {
      "variant": "light-info"
    }
  }, [_vm._v(_vm._s(_vm.akun.jenis_neraca.neraca))]) : _vm.akun.jenis_neraca.neraca == 'Utang Lancar' ? _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(_vm._s(_vm.akun.jenis_neraca.neraca))]) : _vm.akun.jenis_neraca.neraca == 'Ekuitas' ? _c('b-badge', {
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(_vm._s(_vm.akun.jenis_neraca.neraca))]) : _vm._e()], 1)], 1)], 1)], 1), _c('b-col', [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "sm": "6"
    }
  }, [_vm.allowCreate() ? _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-tambah",
      modifiers: {
        "modal-tambah": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.add();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Transaksi Akun ")], 1) : _vm._e()], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "size": "lg",
      "ok-only": "",
      "centered": "",
      "title": "Form "
    },
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('validation-observer', {
    ref: "formakun"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Kas"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "id_kas"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.id_kas,
            "id": "v-id_kas",
            "name": "id_kas"
          },
          model: {
            value: _vm.form.id_kas,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_kas", $$v);
            },
            expression: "form.id_kas"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal & Waktu",
      "label-for": "v-tanggal"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tanggal",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "config": {
              enableTime: false,
              dateFormat: 'Y-m-d'
            }
          },
          model: {
            value: _vm.form.tanggal,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tanggal", $$v);
            },
            expression: "form.tanggal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm.akun.jenis == 1 ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nominal",
      "label-for": "v-debit"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "debit"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          attrs: {
            "prepend": "Rp."
          }
        }, [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-debit",
            "placeholder": "Isi Nominal"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.debit = _vm.formatRupiah(_vm.form.debit);
            }
          },
          model: {
            value: _vm.form.debit,
            callback: function ($$v) {
              _vm.$set(_vm.form, "debit", $$v);
            },
            expression: "form.debit"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }], null, false, 710917194)
  })], 1)], 1) : _vm._e(), _vm.akun.jenis == 2 ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nominal",
      "label-for": "v-kredit"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "kredit"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          attrs: {
            "prepend": "Rp."
          }
        }, [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-kredit",
            "placeholder": "Isi Nominal"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.kredit = _vm.formatRupiah(_vm.form.kredit);
            }
          },
          model: {
            value: _vm.form.kredit,
            callback: function ($$v) {
              _vm.$set(_vm.form, "kredit", $$v);
            },
            expression: "form.kredit"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }], null, false, 3663310122)
  })], 1)], 1) : _vm._e(), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Keterangan",
      "label-for": "v-keterangan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "kterangan"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "v-keterangan",
            "placeholder": "Isi keterangan"
          },
          model: {
            value: _vm.form.keterangan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "keterangan", $$v);
            },
            expression: "form.keterangan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Add ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Per page'),
      "label-for": "perPageSelect"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), (this.user.member_id = null) ? _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Affiliator",
      "label-for": "is_member"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "is_member",
      "options": _vm.optis_member
    },
    on: {
      "input": function ($event) {
        return _vm.getjurnal();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.is_member,
      callback: function ($$v) {
        _vm.is_member = $$v;
      },
      expression: "is_member"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "8",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "size": "sm",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getTransaksi();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter,
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
        _vm.getjurnal();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _vm.items.length > 0 ? [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.akun.jenis == 1 || _vm.akun.jenis == 2 ? _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(member_id)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.getmembername(data.item)) + " ")];
      }
    }, {
      key: "cell(debit)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(item.debit > 0 ? _vm.formatRupiah(item.debit) : item.debit))])];
      }
    }, {
      key: "cell(kredit)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(item.kredit > 0 ? _vm.formatRupiah(item.kredit) : item.kredit))])];
      }
    }, {
      key: "cell(tanggal)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [row.item.modul == 'jurnal' ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Ubah'",
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), row.item.modul == 'jurnal' ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Hapus'",
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }], null, false, 4189984099)
  }) : _vm._e(), _vm.akun.jenis == 3 ? _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.debitfields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(debit)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(item.debit > 0 ? _vm.formatRupiah(item.debit) : item.debit))])];
      }
    }, {
      key: "cell(kredit)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(item.kredit > 0 ? _vm.formatRupiah(item.kredit) : item.kredit))])];
      }
    }, {
      key: "cell(tanggal)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [row.item.modul == 'jurnal' ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Ubah'",
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), row.item.modul == 'jurnal' ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Hapus'",
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }], null, false, 3191181512)
  }) : _vm._e()], 1)] : _vm._e(), _vm.items.length > 0 ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1) : _vm._e(), _vm.items.length == 0 || _vm.items.length == null ? [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "text-center",
    attrs: {
      "border-variant": "danger"
    }
  }, [_c('div', {
    staticClass: "text-danger"
  }, [_vm._v(" Data belum ada ")])])], 1)] : _vm._e()], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }